import { actions as authActions } from "app/modules/Auth/_redux/authRedux";

import * as requestFromServer from "./usersCrud";

import { callTypes, usersSlice } from "./usersSlice";
import { leadsSlice } from "../../PropertiesManagement/_redux/leads/leadsSlice";
import { save, saved } from "../../../../redux/global/globalActions";
import {
  processSnackbarNotification,
  SNACKBAR_MESSAGE,
} from "../../Common/SnackbarNotificationsHandler";
import { cancelFileSignaturesRequest, requestFileSignature } from "../../../_utils/signatureUtils";

const contextualActions = {
  default: usersSlice("users").actions,
  leadsAction: leadsSlice("leads").actions,
  leadClients: usersSlice("leadClients").actions,
};

const usersAction = contextualActions.default;
const leadsAction = contextualActions.leadsAction;
const leadClientsActions = contextualActions.leadClients;

export const fetchUsersByUserType =
  (queryParams = {}, context = "default") =>
  (dispatch) => {
    dispatch(usersAction.startCall({ callType: callTypes.list, queryParams }));
    return requestFromServer
      .getByUserType(queryParams)
      .then((response) => {
        console.log("fetch users by type resp", response);
        const entities = response.items;
        if (!!queryParams?.leadId) {
          dispatch(leadClientsActions.usersFetched({ entities: entities }));
        } else {
          dispatch(usersAction.usersFetched({ entities: entities }));
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (!!queryParams?.leadId) {
          dispatch(leadClientsActions.catchError({ error, callType: callTypes.list }));
        } else {
          dispatch(usersAction.catchError({ error, callType: callTypes.list }));
        }
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_USERS, dispatch);
      });
  };

export const fetchUserById = (id, userTypes) => (dispatch) => {
  if (!id) {
    return dispatch(usersAction.userFetched({ entityForEdit: undefined }));
  }

  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getById(id, userTypes)
    .then((response) => {
      const entityForEdit = response;
      dispatch(usersAction.userFetched({ entityForEdit }));
      return entityForEdit;
    })
    .catch((error) => {
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_USERS, dispatch);
    });
};

export const deleteUserById =
  ({ context, id }) =>
  (dispatch) => {
    dispatch(usersAction.startCall({ callType: callTypes.action }));
    return requestFromServer
      .deleteById(id)
      .then((response) => {
        dispatch(usersAction.userDeleted({ id }));
        if (context) {
          dispatch(contextualActions[context].userDeleted({ id }));
        }
        processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.DELETE_USER, dispatch);
        return id;
      })
      .catch((error) => {
        dispatch(usersAction.catchError({ error, callType: callTypes.action }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.DELETE_USER, dispatch);
      });
  };

export const createUser = (userForCreation) => async (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.create(userForCreation);
    dispatch(usersAction.userCreated({ user: response.user }));
    processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.USER_CREATED, dispatch);
    return response.user;
  } catch (error) {
    console.log("error occurred while creating user", error);
    processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.USER_CREATED, dispatch);
    dispatch(usersAction.catchError({ error, callType: callTypes.action }));
    return error;
  }
};

export const updateUserLocally = (entityForEdit) => (dispatch) => {
  dispatch(usersAction.userUpdatedLocally({ entityForEdit }));
};

export const updateUserFieldLocally = (key, value) => (dispatch) => {
  dispatch(usersAction.userFieldUpdatedLocally({ key, value }));
};

export const updateUser =
  (newUser, updateCurrent = false) =>
  (dispatch) => {
    dispatch(save());
    return requestFromServer
      .update(newUser)
      .then((user) => {
        dispatch(usersAction.userUpdated({ user, updateCurrent }));
        dispatch(saved());
        return user;
      })
      .catch((err) => {
        dispatch(saved());
        if (err?.response?.data?.code === "USER_ALREADY_EXISTS") {
          processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.EMAIL_ALREADY_USED, dispatch);
        } else {
          processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_USER, dispatch);
        }
      });
  };

export const updateCurrentUser = (newUser) => (dispatch) => {
  dispatch(updateUser(newUser, true));
};

export const fetchUserMe = () => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMeByToken()
    .then((response) => {
      const entityForEdit = response;
      dispatch(usersAction.userFetched({ entityForEdit }));
      dispatch(usersAction.userMe({ me: entityForEdit }));
      dispatch(authActions.updadateUserSessionProfilePicture(entityForEdit));
      return entityForEdit;
    })
    .catch((error) => {
      dispatch(usersAction.catchError({ error }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FIND_USER_INFORMATION, dispatch);
    });
};

export const submitUserInformation = (newUser) => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .update(newUser)
    .then((response) => {
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPDATE_PROFILE, dispatch);
      dispatch(usersAction.userUpdated({ user: response, updateCurrent: true }));
    })
    .catch((error) => {
      dispatch(usersAction.catchError({ error, callType: callTypes.action }));
      if (error?.code === "EmailAlreadyUsed") {
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.EMAIL_ALREADY_USED, dispatch);
      } else {
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_PROFILE, dispatch);
      }
    });
};

export const markUserPictureSetup = (user, fileKey) => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .update({ ...user, profilePictureSetup: true, profilePictureKey: fileKey })
    .then((response) => {
      dispatch(usersAction.userUpdated({ user }));
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPDATE_PROFILE_PICTURE, dispatch);
      //dispatch(actions.userProfilePictureFlagUpdated({ user: response }));
    })
    .catch((error) => {
      dispatch(usersAction.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_PROFILE, dispatch);
    });
};

export const createUserAction = (userActionParams) => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createUserAction(userActionParams)
    .then((response) => {
      dispatch(usersAction.userActionCreated({ action: response.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.SEND_COMMUNICATION, dispatch);
    })
    .catch((error) => {
      dispatch(usersAction.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.PERFORM_REQUESTED_ACTION, dispatch);
    });
};

export const createOrResetUserAccess = (id, notifyMethods) => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createOrResetAccess(id, notifyMethods)
    .then((response) => {
      dispatch(usersAction.userFieldUpdatedLocally({ key: "isDisabled", value: false }));
      dispatch(usersAction.userUpdated({ user: response.user }));
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.SEND_COMMUNICATION, dispatch);
    })
    .catch((error) => {
      // dispatch(actions.catchError({error, callType: callTypes.action}));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.ENABLE_USER_OR_RESET_PASSWORD, dispatch);
    });
};

export const removeUserAccess = (id) => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .removeAccess(id)
    .then((response) => {
      // dispatch(actions.catchError({callType: callTypes.action}));
      dispatch(usersAction.userFieldUpdatedLocally({ key: "isDisabled", value: true }));
      dispatch(usersAction.userUpdated({ user: response.user }));
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.REMOVE_USER_ACCESS, dispatch);
    })
    .catch((error) => {
      // dispatch(actions.catchError({error, callType: callTypes.action}));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.REMOVE_USER_ACCESS, dispatch);
    });
};
// FILES
export const updateUserFile = (fileForEdit, userType) => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateFile(fileForEdit, userType)
    .then((response) => {
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPDATE_FILE, dispatch);
      dispatch(usersAction.fileUpdated(response));
    })
    .catch((error) => {
      dispatch(usersAction.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_FILE, dispatch);
    });
};

export const createRequestUserFile = (fileForCreation) => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRequestFile(fileForCreation)
    .then((response) => {
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.REQUEST_FILE_CREATE, dispatch);
      dispatch(usersAction.fileCreated(response));
    })
    .catch((error) => {
      // error.displayMessage = "Can't create request file";
      dispatch(usersAction.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.REQUEST_FILE_CREATE, dispatch);
    });
};

export const uploadRequestedUserFile = (fileForEdit, sessionId) => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .uploadRequestedFile(fileForEdit, sessionId)
    .then((response) => {
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPLOAD_FILE_REQUEST, dispatch);
      dispatch(usersAction.fileUpdated(response));
    })
    .catch((error) => {
      dispatch(usersAction.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_USER, dispatch);
    });
};

export const uploadUserFile = (file, sessionId, setProgress) => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer.uploadUserFile(file, sessionId, setProgress).catch((error) => {
    setProgress(0);
    dispatch(usersAction.catchError({ error, callType: callTypes.action }));
    return Promise.reject({ errorCode: "FILE_UPLOAD_ERROR" });
  });
};

export const createUserFile = (file) => (dispatch) => {
  return requestFromServer
    .createUserFile(file)
    .then((response) => {
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.UPLOAD_FILE, dispatch);
      dispatch(usersAction.fileCreated(response));
      return response;
    })
    .catch((error) => {
      console.log("error occurred: ", error);
      // error.displayMessage = "Can't create file";
      dispatch(usersAction.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPLOAD_FILE, dispatch);
    });
};

export const addLinkedFiles = (props) => (dispatch) =>
  dispatch(usersAction.linkedFilesAdded(props));
export const removeLinkedFile = (props) => (dispatch) =>
  dispatch(usersAction.linkedFileRemoved(props));

export const addClientToLead =
  ({ leadId, userId }) =>
  (dispatch) => {
    dispatch(leadsAction.startCall({ callType: callTypes.action }));
    return requestFromServer
      .createLeadClient({ leadId, userId })
      .then((response) => {
        dispatch(leadsAction.leadClientCreated({ user: response.user }));
        dispatch(leadClientsActions.userCreated({ user: response.user }));
      })
      .catch((error) => {
        // error.displayMessage = "Can't add client to lead";
        dispatch(leadsAction.catchError({ error, callType: callTypes.action }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.ADD_CLIENT_TO_LEAD, dispatch);
      });
  };

export const removeClientFromLead =
  ({ id, leadId }) =>
  (dispatch) => {
    dispatch(contextualActions.leadClients.startCall({ callType: callTypes.action }));
    return requestFromServer
      .removeLeadClient({ id, leadId })
      .then((response) => {
        dispatch(leadsAction.leadClientDeleted({ id }));
        dispatch(leadClientsActions.userDeleted({ id }));
        processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.REMOVE_CLIENT_FROM_LEAD, dispatch);
      })
      .catch((error) => {
        // error.displayMessage = "Can't add client to lead";
        dispatch(contextualActions.leadClients.catchError({ error, callType: callTypes.action }));
        processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.REMOVE_CLIENT_FROM_LEAD, dispatch);
      });
  };

export const deleteUserFile = (file, userType) => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteFile(file, userType)
    .then(() => {
      processSnackbarNotification(SNACKBAR_MESSAGE.SUCCESS.FILE_DELETE, dispatch);
      dispatch(usersAction.fileDeleted({ file }));
    })
    .catch((error) => {
      console.log(error);
      dispatch(usersAction.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.FILE_DELETE, dispatch);
    });
};

export const setUserAvatar = (avatar) => (dispatch) => {
  dispatch(usersAction.startCall({ callType: callTypes.action }));
  return requestFromServer
    .setUserAvatar(avatar)
    .then((response) => {
      dispatch(usersAction.avatarCreated({}));
      return response;
    })
    .catch((error) => {
      dispatch(usersAction.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPLOAD_AVATAR, dispatch);
    });
};

export const updateUserRights = (userForEdit) => (dispatch) => {
  dispatch(save());
  return requestFromServer
    .updateUserRights(userForEdit.id, userForEdit.rights)
    .then((response) => {
      console.log("updateUserRights response: ", response);
      dispatch(usersAction.userRightsUpdated({ user: { ...response, files: userForEdit.files } }));
      dispatch(saved());
    })
    .catch((error) => {
      dispatch(saved());
      dispatch(usersAction.catchError({ error, callType: callTypes.action }));
      processSnackbarNotification(SNACKBAR_MESSAGE.DANGER.UPDATE_USER_RIGHTS, dispatch);
    });
};

//----------------------------------------------------------------------------//

export const requestUserFileSignature = (signatureRequest) => (dispatch) => {
  return requestFileSignature(usersAction, signatureRequest, dispatch, callTypes);
};

export const cancelUserFileSignaturesRequest = (signaturesRequest) => (dispatch) =>
  cancelFileSignaturesRequest(usersAction, signaturesRequest, dispatch, callTypes);
