/* eslint-disable no-restricted-imports */
import * as React from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual } from "react-redux";
import {
  DatePickerField,
  Input,
  Select,
} from "../../../../../../../../_metronic/_partials/controls";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import * as budgetActions from "../../../../../../Budget/_redux/budgetsActions";
import { NumberInput, unloadHandler } from "../../../../../../../_utils/formUtils";
import { cloneDeep, isEqual, mergeWith } from "lodash-es";
import * as actions from "../../../../../_redux/leads/leadsActions";
import { useLeadFilesUIContext } from "../../LeadFilesUIContext";
import { FormattedMessage, useIntl } from "react-intl";
import { formatDisplayNameIntl } from "../../../../../../../_utils/userUtils";
import { INVOICE_STATUS } from "../../../../../../../_utils/listUtils";
import { DateUtils } from "../../../../../../../_utils/DateUtils";
import { Highlighter, Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import {
  fetchSettings,
  updateSettingsFieldLocally,
} from "../../../../../../Settings/_redux/settingsActions";
import { useAppDispatch, useAppSelector } from "../../../../../../../../redux/hooks";
import { canEdit, canReadAll, canReadAllDocument } from "../../../../../../../_utils/authUtils";
import { Checkbox } from "../../../../../../../_components/Checkbox";
import { v4 as uuid } from "uuid";
import { SUFFIX_EURO_CURRENCY } from "../../../../../../../_utils/suffixUtils";
import { LinkedFiles } from "../../../../../../../_components/LinkedFiles";
import { FileDropzone } from "../../../../../../../_components/FileDropzone";
import { useDidMount, useDidUpdate, useWillUnmount } from "rooks";
import { LeadInvoiceLines } from "./LeadInvoiceLines";
import { LeadInvoiceReducedVatInfo } from "./LeadInvoiceReducedVatInfo";
import {
  FinanceFileType,
  IBankAccount,
  ICompany,
  ILeadFile,
  ILeadFinancialDocument,
  IPriceIndex,
  isLeadFinancialDocumentPriceLine,
  LeadFinancialDocumentLine,
  LeadFinancialDocumentLineType,
  LeadFinancialDocumentPriceLine,
  UserType,
} from "../../../../../../../../data/schemas";
import { NumberFormatValues } from "react-number-format";
import { PickPartial } from "../../../../../../../_utils/typesUtils";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { PriceIndexInvoiceDetails } from "../../../../../../../_components/PriceIndex/PriceIndexInvoiceDetails";
import { InvoicePriceDetails } from "./InvoicePriceDetails";
import { getCompanyAvailablePricesIndices } from "../../../../../../../_utils/priceIndexUtils";
import { createPortal } from "react-dom";
import { Notification } from "../../../../../../../_components/Notification";
import { useLeadContext } from "../../../lead-edit/LeadContext";
import { TagManager } from "../../../../../../../_components/TagManager";
import { FILE, TAG } from "../../../../../../../_utils/dataTypes";
import TextareaAutosize from "@material-ui/core/TextareaAutosize/TextareaAutosize";
import { accurateFloatOperation } from "../../../../../../../_utils/mathUtils";
import TitleWithGuideLink from "../../../../../../../_components/TitleWithGuideLink";
import { useLanguage } from "_metronic/i18n";
import { useTranslation } from "app/hooks/useTranslation";
import { CUSTOMER_ID } from "../../../../../../../../configuration";
import { useState } from "react";

export interface ILeadFileInvoiceForm extends ILeadFile {
  notifyUser: string[];
  file: File;
  content: LeadFinancialDocumentLine[];
  bankAccounts: IBankAccount[];
  fillInvoiceInformation: boolean;
  priceIndex: IPriceIndex;
  basePriceIndex: IPriceIndex;
  priceIndexId: string;
  basePriceIndexId: string;
  notes: string;
  subject: string;
  additionalInformation: string;
  isFileUpload: boolean;
}

export type ILeadFileInvoiceFormRes = Omit<ILeadFinancialDocument, "from" | "title" | "tax"> &
  Pick<ILeadFile, "amount" | "amountPaidToDate"> &
  PickPartial<ILeadFile, "relatedEntity">;

interface LeadFileInvoiceFormProps {
  file: ILeadFile;
}

export const LeadFileInvoiceForm: React.FC<LeadFileInvoiceFormProps> = ({ file }) => {
  const intl = useIntl();
  const { fm } = useTranslation();

  const {
    showEditFileDialog,
    closeEditFileDialog,
    newFile,
    setFormSubmitRef,
    resetFormSubmitRef,
    setIsSubmitDisabled,
    setIsInvoice,
    setSubmitButtonLabel,
    setHeaderLabel,
    footerRef,
  } = useLeadFilesUIContext();
  const dispatch = useAppDispatch();

  const { actionsLoading, lead, project, budget, session, groups, generalSettings } =
    useAppSelector(
      (state) => ({
        lead: state.leads?.leadForEdit?.saved!,
        actionsLoading: state.leads?.actionsLoading,
        project:
          state.projects?.projectForEdit?.saved?.id === state.leads?.leadForEdit?.saved?.projectId
            ? state.projects?.projectForEdit?.saved
            : state?.projects?.entities?.find(
                (project) => project.id === state.leads?.leadForEdit?.saved?.projectId
              ),
        budget: state.budgets?.budgetForEdit?.saved,
        session: state.auth.session,
        groups: state.auth.groups,
        generalSettings: state.settings?.settingsForEdit?.current,
      }),
      shallowEqual
    );

  const settings: ICompany | undefined = project?.projectOwner ?? generalSettings;

  const [priceIndices, setPriceIndices] = React.useState([]);

  React.useEffect(() => {
    setPriceIndices(getCompanyAvailablePricesIndices(project?.projectOwner, true));
  }, [project?.projectOwner]);

  const { selectedLanguage: lang } = useLanguage();

  const invoiceInit = {
    friendlyName: undefined,
    file: undefined,
    invoiceStatus: "TO_BE_PAID",
    dueDate: undefined,
    invoiceDate: new Date(),
    amount: 0,
    amountPaidToDate: 0,
    notifyUser: [],
    selectedForFinance: true,
    isConfidential: canReadAllDocument(groups, session, "LEAD"),
    content: [
      {
        id: uuid(),
        budgetInstalmentId: undefined,
        financialDocumentId: undefined,
        label: "",
        amount: 0,
        indexedAmount: 0,
        vat: 0,
        lineType: LeadFinancialDocumentLineType.PRICING,
      },
    ],
    bankAccounts: [],
    fillInvoiceInformation: true,
    priceIndex: undefined,
    basePriceIndex: undefined,
    priceIndexId: undefined,
    basePriceIndexId: undefined,
    subject: lead.subject,
    additionalInformation: settings?.invoiceAdditionalInformation?.[lang] || "",
    isFileUpload: false,
  };

  const mergeWithInitInvoice = (obj: any) => {
    return mergeWith(cloneDeep(invoiceInit), obj, (dest, src) => (src === null ? dest : undefined));
  };

  const [initialValues, setInitialValues] = React.useState<ILeadFileInvoiceForm>(
    file as ILeadFileInvoiceForm
  );

  const [progress, setProgress] = useState(0);

  // Validation schema
  const baseSchema = {
    friendlyName: Yup.string().min(2).max(250).required().label("INVOICE.TITLE.TITLE"),
    invoiceStatus: Yup.string(),
    amount: Yup.number(),
    amountPaidToDate: Yup.number().nullable(true),
  };
  let newFileSchema = {};
  if (newFile) {
    newFileSchema = {
      file: Yup.mixed().when("fillInvoiceInformation", {
        is: false,
        then: (schema) => schema.required(),
      }),
      fillInvoiceInformation: Yup.boolean(),
      content: Yup.array().of(
        Yup.object().shape({
          budgetInstalmentId: Yup.string().when("label", {
            is: undefined,
            then: (schema) => schema.required(),
          }),
          label: Yup.string().required(),
          amount: Yup.number().when("label", {
            is: undefined,
            then: (schema) => schema.required(),
          }),
          indexedAmount: Yup.number().when("label", {
            is: undefined,
            then: (schema) => schema.required(),
          }),
          vat: Yup.number().when("label", {
            is: undefined,
            then: (schema) => schema.required(),
          }),
        })
      ),
    };
  }
  let InvoiceSchema = Yup.object().shape({ ...baseSchema, ...newFileSchema });

  const formRef = React.useRef<FormikProps<ILeadFileInvoiceForm>>(null);

  useDidMount(() => {
    if (!setFormSubmitRef) return;
    setFormSubmitRef(() => {
      const form = formRef.current;
      if (form) {
        form.submitForm();
      }
    });
    if (newFile) {
      setHeaderLabel("INVOICE.ACTION.CREATE.NEW");
      setSubmitButtonLabel("INVOICE.ACTION.CREATE.NEW");
    }
  });

  React.useEffect(() => {
    setIsSubmitDisabled(actionsLoading);
  }, [actionsLoading]);

  useWillUnmount(() => {
    resetFormSubmitRef();
    setIsInvoice(false);
    setSubmitButtonLabel();
    setHeaderLabel();
  });

  useDidUpdate(() => {
    if (isEqual(initialValues, file) && generalSettings) {
      const today = new Date();
      let invoiceName = "INVOICE #" + DateUtils.format(today, intl);
      const invoiceNumber = ((generalSettings?.hardcodedInvoiceCounter ?? 0) + 1)
        .toString()
        .padStart(4, "0");
      if (CUSTOMER_ID === "kabe" || generalSettings?.hardcodedInvoiceNameFormat === "kabe") {
        invoiceName = `FACTURE-${today.getFullYear()}${invoiceNumber}`;
      } else if (CUSTOMER_ID === "fecc" || generalSettings?.hardcodedInvoiceNameFormat === "fecc") {
        const formattedDate = today.toISOString().split("T")[0].replaceAll("-", "");
        const clientNames = lead.users.map((user) => user.firstName[0] + user.lastName);
        invoiceName = `${formattedDate}-${invoiceNumber}-${clientNames.join("-")}`;
      }
      setInitialValues(
        mergeWithInitInvoice({
          ...file,
          friendlyName: invoiceName,
        })
      );
    }
  }, [showEditFileDialog, file, lead, generalSettings]);

  const { usedConstructionPriceWithReducedVat } = useLeadContext();

  const [currentConstructionPriceWithReducedVat, setCurrentConstructionPriceWithReducedVat] =
    React.useState<number>(usedConstructionPriceWithReducedVat);

  React.useEffect(() => {
    if (project) {
      fetchSettings(dispatch);
    }
    if (
      project?.selectedBudget &&
      (!budget || budget.projectId !== project?.id) &&
      canReadAll(groups, session, "BUDGET")
    ) {
      dispatch(budgetActions.fetchBudget(project?.selectedBudget));
    }
  }, [project]);

  const handleValueChange = <T extends keyof ILeadFileInvoiceForm>(
    field: T,
    values: ILeadFileInvoiceForm,
    value: ILeadFileInvoiceForm[T],
    setFieldValue: FormikHelpers<ILeadFileInvoiceForm>["setFieldValue"]
  ) => {
    const res = cloneDeep(values);
    res[field] = value;
    setFieldValue(field, res[field]);
  };

  const splitMixVatLine = (
    line: LeadFinancialDocumentPriceLine
  ): {
    defaultVatLine: LeadFinancialDocumentPriceLine;
    reducedVatLine: LeadFinancialDocumentPriceLine;
  } => {
    const defaultVatPart = accurateFloatOperation(
      Math.abs(lead.constructionPriceWithReducedVat! - currentConstructionPriceWithReducedVat),
      2
    );
    const basePriceIndexCoef = line.amount / line.indexedAmount;

    const { relatedFinancialDocuments, ...lineAlone } = line;
    return {
      defaultVatLine: {
        ...lineAlone,
        vat: settings?.defaultVat ?? 0,
        amount: accurateFloatOperation(defaultVatPart * basePriceIndexCoef, 2),
        indexedAmount: defaultVatPart,
        lineType: LeadFinancialDocumentLineType.PRICING,
      },
      reducedVatLine: {
        ...line,
        vat: settings?.reducedVat ?? 0,
        amount: accurateFloatOperation(
          (line.indexedAmount - defaultVatPart) * basePriceIndexCoef,
          2
        ),
        indexedAmount: accurateFloatOperation(line.indexedAmount - defaultVatPart, 2),
      },
    };
  };

  const handleMixVatLine = (content: LeadFinancialDocumentLine[]) => {
    let additionalLine:
      | {
          line: LeadFinancialDocumentPriceLine;
          index: number;
          relatedFinancialDocumentIndex?: number;
          relatedFinancialDocumentLineIndex?: number;
        }
      | undefined;

    // Break loop when mix VAT line found has there can be only one line with mix VAT
    contentLoop: for (let [lineIndex, line] of content.entries()) {
      if (isLeadFinancialDocumentPriceLine(line) && line.hasMixVat) {
        const { reducedVatLine, defaultVatLine } = splitMixVatLine(line);
        additionalLine = {
          line: defaultVatLine,
          index: lineIndex + 1,
        };
        content[lineIndex] = reducedVatLine;
        break;
      }
      for (let [relatedFDIndex, relatedFD] of line.relatedFinancialDocuments?.entries() ?? []) {
        for (let [relatedFDLineIndex, relatedFDLine] of relatedFD.content.entries()) {
          if (isLeadFinancialDocumentPriceLine(relatedFDLine) && relatedFDLine.hasMixVat) {
            const { reducedVatLine, defaultVatLine } = splitMixVatLine(relatedFDLine);
            additionalLine = {
              line: defaultVatLine,
              index: lineIndex,
              relatedFinancialDocumentIndex: relatedFDIndex,
              relatedFinancialDocumentLineIndex: relatedFDLineIndex + 1,
            };
            relatedFD.content[relatedFDLineIndex] = reducedVatLine;
            break contentLoop;
          }
        }
      }
    }

    if (additionalLine) {
      if (!("relatedFinancialDocumentIndex" in additionalLine)) {
        content.splice(additionalLine.index, 0, additionalLine.line);
      } else {
        const relatedFDLines =
          content[additionalLine.index].relatedFinancialDocuments![
            additionalLine.relatedFinancialDocumentIndex!
          ].content;
        relatedFDLines.splice(
          additionalLine.relatedFinancialDocumentLineIndex!,
          0,
          additionalLine.line
        );
      }
    }
    return content;
  };

  const formatInvoice = (values: ILeadFileInvoiceForm) => {
    const res: Partial<ILeadFileInvoiceFormRes> = {
      ...values,
      fileType: FinanceFileType.INVOICE,
      leadId: lead.id!,
      relatedEntity: { name: lead.name } as ILeadFile["relatedEntity"],
      projectId: project?.id!,
      propCoId: project?.projectOwner?.id,
      content: handleMixVatLine(cloneDeep(values).content),
      product: {
        projectName: project?.name!,
        productName: lead.product?.name,
        address: lead.product?.address,
      },
      bankAccounts: selectedBankAccounts.map((account) => account.id),
      amountPaidToDate:
        values.invoiceStatus === "PARTIALLY_PAID" ? values.amountPaidToDate : undefined,
      authorisationCode: lead.eligibleReducedVat ? lead.authorisationCode! : "",
      subject: values.subject,
    };
    const clients = lead.users?.map((user) => formatDisplayNameIntl(intl, user));
    if (lead.invoiceAddressType === "CUSTOM") {
      res.to = {
        name:
          (lead.invoiceAddress?.name ? lead.invoiceAddress?.name + "\n" : "") + clients?.join("\n"),
        address: lead.invoiceAddress?.address ?? {},
      };
    } else {
      const clientAddress =
        lead.users?.find((user) => user.id === lead.invoiceAddressType)?.address ?? {};
      res.to = {
        name: clients?.join(",\n") ?? "",
        address: clientAddress,
      };
    }
    return res;
  };

  const saveInvoice = (
    values: ILeadFileInvoiceForm,
    formikHelpers: FormikHelpers<ILeadFileInvoiceForm>
  ) => {
    window.addEventListener("beforeunload", unloadHandler);
    if (!newFile) {
      dispatch(actions.updateFile(values)).then(() => {
        closeEditFileDialog();
        window.removeEventListener("beforeunload", unloadHandler);
      });
    } else {
      if (!values.fillInvoiceInformation) {
        const { leadId, isConfidential, friendlyName, file, subject } = values;
        const leadFile = {
          leadId,
          isConfidential,
          friendlyName,
          fileType: FinanceFileType.INVOICE,
          file,
          relatedEntity: { name: lead.name },
          subject,
        };
        dispatch(actions.uploadLeadFile(leadFile, session?.id, setProgress))
          .then(({ file }) => {
            dispatch(actions.createLeadFile(file)).then(() => {
              closeEditFileDialog();
              setProgress(0);
              window.removeEventListener("beforeunload", unloadHandler);
            });
          })
          .catch((e) => {
            window.removeEventListener("beforeunload", unloadHandler);
            formikHelpers.setFieldError("file", "FILE_UPLOAD_ERROR");
          });
      } else {
        dispatch(
          actions.createFinancialDocument({
            ...formatInvoice(values),
            sessionId: session?.id,
            file: values.file,
            language: intl.locale,
          })
        ).then(async () => {
          updateSettingsFieldLocally(
            "hardcodedInvoiceCounter",
            (generalSettings?.hardcodedInvoiceCounter ?? 0) + 1
          )(dispatch);
          closeEditFileDialog();
          window.removeEventListener("beforeunload", unloadHandler);
        });
      }
    }
  };

  const [selectedBankAccounts, setSelectedBankAccounts] = React.useState<IBankAccount[]>([]);
  const updateSelectedBankAccounts = (bankAccounts: IBankAccount[]) => {
    setSelectedBankAccounts(bankAccounts);
  };

  const reducedVatAvailable = React.useMemo(
    () =>
      !!(
        lead?.eligibleReducedVat &&
        lead?.authorisationCode &&
        lead?.authorisationDate &&
        new Date(lead.authorisationDate) < new Date()
      ),
    [lead]
  );

  const contentIncludesSubcontractorFiles = (values: ILeadFileInvoiceForm) => {
    return values.content?.some(
      (line) => line.financialDocumentId || line.relatedFinancialDocuments?.length
    );
  };

  const [notesEnabled, setNotesEnabled] = React.useState(false);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={InvoiceSchema}
      onSubmit={saveInvoice}
    >
      {({ setFieldValue, values, handleChange, errors }) => (
        <Modal.Body>
          <Form className="form form-label-right">
            {values.fillInvoiceInformation &&
              newFile &&
              !!footerRef?.current &&
              createPortal(
                <InvoicePriceDetails handleMixVatLine={handleMixVatLine} />,
                footerRef.current
              )}
            <div className="row mb-4">
              <div className="col-12">
                <Field
                  name="friendlyName"
                  data-cy="title-invoice-form"
                  component={Input}
                  placeholder={intl.formatMessage({
                    id: "INVOICE.TITLE.TITLE",
                  })}
                  label={intl.formatMessage({
                    id: "INVOICE.TITLE.TITLE",
                  })}
                />
              </div>
            </div>
            {((!newFile && values.invoiceStatus) || values.fillInvoiceInformation) && (
              <div className="row mb-4">
                <div className="col-sm-4">
                  <Select
                    name="invoiceStatus"
                    label={intl.formatMessage({
                      id: "COMMON.STATUS",
                    })}
                    customFeedbackLabel=""
                  >
                    {Object.entries(INVOICE_STATUS).map(([key, val]) => (
                      <option key={key} value={key}>
                        {intl.formatMessage({ id: val })}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="col-sm-4">
                  <label>
                    <FormattedMessage id="INVOICE.INVOICE_DATE" />
                  </label>
                  <div className="position-relative">
                    <Field
                      name="invoiceDate"
                      showTimeSelect={false}
                      placeholderText={DateUtils.format(new Date(), intl, false)}
                      component={DatePickerField}
                      withFeedbackLabel={false}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e: Date) => {
                        if (e === null) e = new Date();
                        const x = new Date().getTimezoneOffset() * 60000;
                        const localISOTime = new Date(e.getTime() - x).toISOString().slice(0, -1);
                        return setFieldValue("invoiceDate", localISOTime);
                      }}
                    />
                  </div>
                </div>
                {values?.invoiceStatus !== "PAID" && (
                  <div className="col-sm-4">
                    <label>
                      <FormattedMessage id="TASK.LABEL.DATE.DUE" />
                    </label>
                    <div className="position-relative">
                      <Field
                        name="dueDate"
                        showTimeSelect={false}
                        placeholderText={DateUtils.format(new Date(), intl, false)}
                        component={DatePickerField}
                        withFeedbackLabel={false}
                        dateFormat="dd/MM/yyyy"
                        onChange={(e: Date) => {
                          const x = new Date().getTimezoneOffset() * 60000;
                          const localISOTime = new Date(e.getTime() - x).toISOString().slice(0, -1);
                          return setFieldValue("dueDate", localISOTime);
                        }}
                      />
                      {values.dueDate && (
                        <span
                          className="position-absolute right-0 mr-4 cursor-pointer"
                          style={{ top: "50%", transform: "translateY(-50%)" }}
                          onClick={() => {
                            setFieldValue("dueDate", "");
                          }}
                        >
                          <i className="fas fa-times" />
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {values?.invoiceStatus === "PARTIALLY_PAID" && (
                  <div className="col-sm-4 mt-4">
                    <label>
                      <FormattedMessage id="TASK.LABEL.PAID_TO_DATE" />
                    </label>
                    <NumberInput
                      suffix={SUFFIX_EURO_CURRENCY}
                      name="amountPaidToDate"
                      className="form-control text-right"
                      value={values.amountPaidToDate}
                      onValueChange={(e: NumberFormatValues) => {
                        handleValueChange(
                          "amountPaidToDate",
                          values,
                          e.floatValue ?? 0,
                          setFieldValue
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            )}

            {values.fillInvoiceInformation && newFile && (
              <div className="form-group form-row">
                <div className="col-12">
                  <label>
                    <FormattedMessage id="SETTING.BANK.ACCOUNT" />
                  </label>
                  <Typeahead
                    id="invoice-bank-accounts-typeahead-input"
                    paginate={false}
                    multiple
                    positionFixed
                    labelKey="name"
                    filterBy={["name", "IBAN", "BIC"]}
                    selected={selectedBankAccounts}
                    onChange={(selected) => {
                      updateSelectedBankAccounts(selected);
                    }}
                    options={project?.projectOwner?.bankAccounts ?? settings?.bankAccounts ?? []}
                    placeholder={intl.formatMessage({ id: "COMMON.SELECT_BANK_ACCOUNT" })}
                    disabled={actionsLoading}
                    className="flex-grow-1"
                    renderMenu={(accounts, menuProps, state) => (
                      <Menu {...menuProps}>
                        {accounts.map((account, index) => (
                          <MenuItem option={account} position={index} key={index}>
                            <span className="font-weight-bold">
                              <Highlighter
                                search={state?.text ?? ""}
                                highlightClassName="bg-light-primary p-0"
                              >
                                {account.name}
                              </Highlighter>
                            </span>
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  />
                </div>
              </div>
            )}
            {values.fillInvoiceInformation && newFile && (
              <>
                <div className="separator separator-solid separator-border-2 my-4" />
                <div className="row mb-4">
                  <div className="col-12">
                    <label className="font-size-lg font-weight-bold">
                      <FormattedMessage id="LEAD.TITLE.SUBJECT" />
                    </label>
                    <label>
                      <FormattedMessage id="INVOICE.SUBJECT_INFO_MESSAGE" />
                    </label>
                    <Field
                      name="subject"
                      data-cy="title-subject-form"
                      component={Input}
                      placeholder={`${lead?.product?.projectName || ""}${
                        lead?.product?.name ? `, ${lead?.product?.name}` : ""
                      }`}
                    />
                  </div>
                </div>
                <div className="separator separator-solid separator-border-2 my-4" />
              </>
            )}
            {values.fillInvoiceInformation &&
              newFile &&
              (!settings ? (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="spinner spinner-lg spinner-primary h-30px w-30px" />
                </div>
              ) : (
                <>
                  {reducedVatAvailable && (
                    <>
                      <LeadInvoiceReducedVatInfo
                        {...{
                          settings,
                          lead,
                          currentConstructionPriceWithReducedVat,
                        }}
                      />
                      <div className="separator separator-solid separator-border-2 my-4" />
                    </>
                  )}
                  {project && (
                    <>
                      <PriceIndexInvoiceDetails
                        priceIndices={priceIndices}
                        project={project}
                        baseLeadPriceIndex={lead.baseLeadPriceIndex}
                      />
                      <div className="separator separator-solid separator-border-2 my-4" />
                    </>
                  )}
                  <LeadInvoiceLines
                    {...{
                      settings,
                      lead,
                      budget,
                      currentConstructionPriceWithReducedVat,
                      setCurrentConstructionPriceWithReducedVat,
                      reducedVatAvailable,
                    }}
                  />
                  <div className="separator separator-solid separator-border-2 my-4" />
                  <div className="mt-4">
                    <div className="d-flex justify-content-between mb-1">
                      <div className="d-flex flex-column">
                        <label className="font-size-lg font-weight-bold">
                          <FormattedMessage id={"NOTE.TITLE"} />
                        </label>
                        <label>
                          <FormattedMessage id="INVOICE.NOTES_INFO_MESSAGE" />
                        </label>
                      </div>
                      {!notesEnabled && (
                        <button
                          type="button"
                          className="btn btn-icon btn-icon-primary"
                          onClick={() => setNotesEnabled(true)}
                        >
                          <i className="ki ki-plus icon-md"></i>
                        </button>
                      )}
                    </div>
                    {notesEnabled && (
                      <div className="d-flex flex-column">
                        <Field
                          as={TextareaAutosize}
                          name="notes"
                          className="form-control form-control-sm"
                          value={values.notes}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleValueChange("notes", values, e.target.value, setFieldValue)
                          }
                          minRows={4}
                        />
                        <div className="align-self-end mt-1">
                          <button
                            type="button"
                            className="btn btn-sm btn-text-dark btn-hover-light-dark"
                            onClick={() => {
                              handleValueChange("notes", values, "", setFieldValue);
                              setNotesEnabled(false);
                            }}
                          >
                            <FormattedMessage id="COMMON.ACTION.CANCEL" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ))}
            {newFile && (
              <>
                <div className="separator separator-solid separator-border-2 my-4" />
                <div className="row mb-4">
                  <div className="col-12 d-flex flex-column">
                    <label className="font-size-lg font-weight-bold">
                      <FormattedMessage id="ADDITIONAL.INFORMATION" />
                    </label>
                    <label>
                      <FormattedMessage id="INVOICE.ADDITIONAL.INFORMATION" />
                    </label>
                    <Field
                      name="additionalInformation"
                      data-cy="title-subject-form"
                      component={Input}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="separator separator-solid separator-border-2 my-4" />
            {values.fillInvoiceInformation && newFile && (
              <div className="d-flex justify-content-between mb-1 flex-column">
                <div className="d-flex flex-column">
                  <label className="font-size-lg font-weight-bold">
                    <FormattedMessage id="INVOICE.FILE_UPLOAD.TITLE" />
                  </label>
                  <label>
                    <FormattedMessage id="INVOICE.FILE_UPLOAD.MESSAGE" />
                  </label>
                </div>
                <Checkbox name="isFileUpload" label="INVOICE.FILE_UPLOAD.CHECKBOX" />
              </div>
            )}
            {newFile && values.isFileUpload && (
              <div className="form-group row mt-4">
                <div className="col-lg-12">
                  {actionsLoading && !!values.file ? (
                    <div className="upload-file-container upload-file-container--disabled">
                      <div className="d-flex justify-content-center align-middle">
                        <div className="spinner-grow text-primary mr-4" />
                        <div>
                          <FormattedMessage id="FILE.ACTION.UPLOADING" /> {progress}%
                        </div>
                      </div>
                    </div>
                  ) : (
                    <FileDropzone values={values} setFieldValue={setFieldValue} />
                  )}
                </div>
                {errors?.file === "FILE_UPLOAD_ERROR" && (
                  <div className="col col-12 text-danger mt-2">
                    <FormattedMessage id="FILE.UPLOAD_FAIL" />
                  </div>
                )}
              </div>
            )}
            {canReadAll(groups, session, TAG) && !newFile && (
              <div className="form-group row">
                <div className="col-lg-12" data-cy="select-custom-tags">
                  <TagManager fullWidth relatedTo={`${FILE}#${file.id}`} />
                </div>
              </div>
            )}

            {!values.file && contentIncludesSubcontractorFiles(values) && (
              <>
                <Checkbox
                  name="includeSubcontractorFiles"
                  label="LEAD.INCLUDE_SUBCONTRACTOR_FILES"
                />
                <div className="text-warning mb-4 font-size-sm">
                  <span className="svg-icon svg-icon-md svg-icon-warning mr-2">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
                  </span>
                  <FormattedMessage id="LEAD.INCLUDE_SUBCONTRACTOR_FILES.WARNING_MESSAGE" />
                </div>
              </>
            )}
            {canEdit(groups, session, "BUDGET") && (
              <>
                <TitleWithGuideLink
                  titleMessageId="FILE.FINANCE_ANALYSIS.TITLE"
                  descriptionMessageId="FILE.FINANCE_ANALYSIS.DESCRIPTION"
                  guideLink={fm("FILE.FINANCE_ANALYSIS.LINK")}
                />
                <Checkbox
                  name="selectedForFinance"
                  label="FILE.ACTION.SELECT_FOR_FINANCE_ANALYSIS"
                  customClasses="mb-2"
                />
              </>
            )}
            {canReadAllDocument(groups, session, "LEAD") && (
              <>
                <TitleWithGuideLink
                  titleMessageId="COMMON.CONFIDENTIAL.DOCUMENT"
                  descriptionMessageId="FILE.CONFIDENTIAL.DOCUMENT.DESCRIPTION"
                  guideLink={fm("FILE.CONFIDENTIAL.DOCUMENT.LINK")}
                />
                <Checkbox name="isConfidential" label="COMMON.CONFIDENTIAL.DOCUMENT" />
              </>
            )}
            {!values.isConfidential && (
              <div className="form-group row mt-4">
                <div className="col-lg-12">
                  <Notification
                    notifyMethods={values.notifyUser}
                    userType={UserType.CLIENT}
                    setNotifyMethods={(options) =>
                      handleChange({
                        target: {
                          name: "notifyUser",
                          value: options,
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
            <div className="separator separator-solid separator-border-2 my-4" />
            <LinkedFiles activeFile={values} />
          </Form>
        </Modal.Body>
      )}
    </Formik>
  );
};
