import { API } from "@aws-amplify/api";
import { uploadFile } from "../../../../_utils/fileUtils";

export async function createProject(project) {
  const body = { ...project };
  return await API.post("API", "/projects", { body });
}

export async function updateProject(project) {
  const body = { ...project };
  return await API.post("API", `/projects/${project.id}`, { body });
}

export async function getProjectById(id) {
  return await API.get("API", `/projects/${id}`, {});
}

export async function getProjectRelatedObjectIds(id) {
  return await API.get("API", `/projects/${id}/related`, {});
}

export const getProjectOwnerAssignees = async () => await API.get("API", `/projects/owners`, {});

export async function findProjects(queryParams) {
  const response = {
    items: [],
  };
  if (!!queryParams?.projectId) {
    const apiResponse = await API.get("API", "/projects", {
      queryStringParameters: {
        projectId: queryParams.projectId,
      },
    });
    response.items = apiResponse;
  } else {
    const apiResponse = await API.get("API", "/projects", {});
    response.items = apiResponse;
  }
  return response;
}

export async function deleteProject(id) {
  return await API.del("API", `/projects/${id}`, {});
}

export const createProjectFile = async (file) => {
  return await API.post("API", `/projects/${file.projectId}/files`, {
    body: file,
  });
};

export const uploadProjectFile = async (file, sessionId, setProgress) => {
  return await uploadFile(
    file,
    sessionId,
    {
      type: "PROJECT",
      id: file.projectId,
    },
    setProgress
  );
};

export const updateFile = async (file) => {
  return await API.post("API", `/projects/${file.projectId}/files/${file.id}`, {
    body: file,
  });
};

export const getFile = async (file) => {
  return await API.get("API", `/projects/${file.projectId}/files/${file.id}`, {});
};

export const deleteFile = async (file) => {
  return await API.del("API", `/projects/${file.projectId}/files/${file.id}`, {});
};

export const createProjectSharing = async (projectSharing) => {
  return await API.post("API", `/projects/${projectSharing.projectId}/sharing`, {
    body: projectSharing,
  });
};

export const removeProjectSharing = async (projectSharing) => {
  return await API.del(
    "API",
    `/projects/${projectSharing.projectId}/sharing/${projectSharing.userId}`,
    {}
  );
};

export const createProjectSubcontractor = async (projectSubcontractor) => {
  return await API.post("API", `/projects/${projectSubcontractor.projectId}/subcontractor`, {
    body: projectSubcontractor,
  });
};

export const removeProjectSubcontractor = async (projectSubcontractor) => {
  return await API.del(
    "API",
    `/projects/${projectSubcontractor.projectId}/subcontractor/${projectSubcontractor.userId}`,
    {}
  );
};

export const getPhotosByProjectId = async (projectId) => {
  return await API.get("API", `/projects/${projectId}/photos`, {});
};

export const createPhoto = async (photo) => {
  const { projectId, typePhoto, file } = photo;
  const response = await API.post("API", `/projects/${projectId}/photos`, {
    body: { typePhoto, isPrivate: false },
  });
  try {
    const fetchResponse = await fetch(response.url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": "",
      },
    });
    console.log("fetchResponse:", fetchResponse);
    return response;
  } catch (e) {
    console.log("axios error:");
    console.log(e);
  }
};

export const updatePhoto = async (photo) => {
  return await API.post("API", `/projects/${photo.projectId}/photos/${photo.id}`, {
    body: { ...photo },
  });
};

export async function updatePhotosOrder(projectId, photosOrder) {
  return await API.post("API", `/projects/${projectId}/photos/ordering`, { body: { photosOrder } });
}

export const deletePhoto = async ({ projectId, photoId }) => {
  return await API.del("API", `/projects/${projectId}/photos/${photoId}`, {});
};

export const createPhotoSharing = async (photoSharing) => {
  return await API.post(
    "API",
    `/projects/${photoSharing.projectId}/photos/${photoSharing.photoId}/sharing`,
    {
      body: photoSharing,
    }
  );
};

export const deletePhotoSharing = async ({ projectId, photoId, userId }) => {
  return await API.del("API", `/projects/${projectId}/photos/${photoId}/sharing/${userId}`, {});
};

export const setProjectAvatar = async (avatar) => {
  const { projectId, fileName, image } = avatar;
  const response = await API.post("API", `/projects/${projectId}/avatar`, {
    body: { projectId, fileName },
  });
  try {
    const fetchResponse = await fetch(response.url, {
      method: "PUT",
      body: image,
      headers: {
        "Content-Type": "",
      },
    });
    console.log("fetchResponse:", fetchResponse);
  } catch (e) {
    console.log("axios error:", e);
  }
  return response;
};
